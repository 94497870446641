import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ApplyPage = () => {
    return (
        <Layout>
            <Seo title="Apply to StemAI" />
            <h1>Join StemAI</h1>
            <div className="iframe-container">
            <iframe className="airtable-embed" src="https://airtable.com/embed/app7qauVF4tHHiPOB/pagK7BawXEU9Z561S/form" width="100%" height="100%" style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
            </div>
        </Layout>
    )
}

export default ApplyPage
